import React from 'react'

// App components
import CustomLink from '../../components/CustomLink'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App contnet
import foodsToAvoid from '../../assets/Thumbnail_Avoid.png'
import foodsToEnjoy from '../../assets/Thumbnail_Enjoy.png'
import { Freebie } from '../../components/Freebie'
import malia from '../../assets/malia.jpg'

// App utils
import { themeStyles, colors } from '../../utils/theme'

const matchPColor = { color: 'hsla(0,0%,0%,0.6)' }

const freebies = [
  {
    image: foodsToAvoid,
    buttonText: 'Download the List!',
    link: 'https://res.cloudinary.com/collins-ortho/image/upload/v1688334091/pdfs/FoodsToAvoid.pdf',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Ever wonder what foods you should stay away from?
        </p>
        <h2 css={matchPColor}>Avoiding Sticky and Hard Foods</h2>
        <p>
          Avoiding sticky and hard foods during your time in braces is for your
          benefit. Hard foods can break braces / appliances off of your teeth,
          and if this continually happens throughout your treatment, your
          overall time in braces could be significantly increased.
        </p>
        <p>
          Furthermore, sticky foods can not only break braces, but can sometimes
          get stuck in between braces. This will increase your risk for gum
          disease, cavities, and other dental problems. We’ve compiled this list
          of foods to avoid during your time in braces.
        </p>
      </div>
    ),
  },
  {
    image: foodsToEnjoy,
    buttonText: 'Download the List!',
    link: 'https://res.cloudinary.com/collins-ortho/image/upload/v1688341687/pdfs/FoodsToEnjoy.jpg',
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Don't you still want to eat well with braces?
        </p>
        <h2 css={matchPColor}>Foods You Can Enjoy</h2>
        <p>
          For every food you must avoid during braces, there is an equally tasty
          food you can enjoy! Here is a list of foods that can be enjoyed during
          your time in braces.
        </p>
      </div>
    ),
  },
  {
    image: malia,
    buttonText: 'Get Cooking',
    link: '/resources/braces-friendly-eats',
    noMargin: true,
    content: (
      <div>
        <p css={{ marginBottom: 0, color: colors.brightBlue }}>
          Want the best recipes for braces?
        </p>
        <h2 css={matchPColor}>Braces Friendly Recipes</h2>
        <p>
          When our patient, Malia, prepared for 
          <CustomLink to={'/orthodontics/braces'}>clear braces</CustomLink> treatment at 
          Collins Orthodontics, she shared her plan with us. Her meal plan! She
          jumped on the internet, found the foods perfect for her first week in
          braces, and prepped them before ever sitting back in our chair at
          Collins Orthodontics.
        </p>
        <p>
          The results? Collins Orthodontics presents 
          <strong>Braces Friendly Eats featuring Malia Howe</strong> – healthy
          foods you’ll love that won’t irritate your smile while you start your
          treatment.
        </p>
        <p>
          You can see the recipes here:{' '}
          <CustomLink to="/resources/braces-friendly-eats">
            BracesFriendlyEats
          </CustomLink>
        </p>
      </div>
    ),
  },
]

class Page extends React.Component {
  render() {
    const freebieElements = freebies.map((item) => <Freebie key={item.link} {...item} />)
    return (
      <Layout location={this.props.location}>
        <div css={{ ...themeStyles.contentWidth }}>
          <PageTitle title="Eating with Braces" bannerKey={'RESOURCES'} />

          {freebieElements}
        </div>
      </Layout>
    )
  }
}

export default Page
